@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

@import "base";

@import "typography";
@import "sections";
@import "header";
@import "buttons";
@import "navbar";
@import "footer";
@import "form";
// @import "hero";
// @import "header";
// @import "media_scroller";
// @import "cards";
// @import "forms";
@import "pages_overrides";
@import "blog";

body.page {
  margin-top: 5rem;
}