.blog-hero {
  padding: 5em 1em;
  border: 0px solid var(--color-accent);
  border-width: 0.1em 0;
  margin-block-end: 5em;

  h1 {
    --size-base: clamp(20px, calc(var(--font-size-title-base) * 2), 1.75em);
    margin-bottom: 0.25em;
  }

  .additional-info {
    display: flex;
    gap: 3ch;
    flex-direction: column;
    .locale { display: none; }
    .by-line {
      font-style: italic;
    }
    .technologies {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5em 1ch;
      .technology-label {
        display: inline-block;
        break-inside: avoid;
      }
    }
    
  }
}
@media screen and (min-width: 768px) {
  .blog-hero {
    .additional-info {
      flex-direction: row;

      .locale { display: block; }
    }
  }
}

span.technology-label {
  --border: var(--color-accent);
  --background: transparent;
  --foreground: var(--color-text);

  border: 1px solid var(--border);
  background-color: var(--background);
  color: var(--foreground);

  padding: 0.25em 0.5em;
  border-radius: 1em;

  margin-inline-end: 1ch;

  &.tech-ruby {
    --border: #9b1c16;
    --background: #CC342D;
    --foreground: white;
  }
}


nav.blog-navbar {
  padding: 1em 0.5em;
  font-family: var(--font-family-code);

  ol {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1ch;
  }

  li {
    list-style: none;
  }

  li+li::before {
    float: left;
    padding-right: 1ch;
    content: '>'
  }
}

body.blog-index {
  .hero {
    @extend .blog-hero;
  }

  .blog-post-list {
    .blog-post-item {
      max-width: 60ch;
      padding: 1rem;
      outline: 1px solid var(--color-border);
      margin-block-end: var(--separation-block);
      isolation: isolate;
      position: relative;
      border-radius: var(--border-radius-rounded);

      .additional-info {
        display: flex;
        align-items: baseline;
        gap: 1ch;
        margin-block-end: var(--separation-block-sm);

        .by-line {
          font-style: italic;
        }

        .technologies {
          font-size: .75em;
        }
      }

      .links {
        padding: .5em;
        display: flex;
        justify-content: flex-end;
      }

      a {
        @extend .button-action;
        @extend .button-hover-react;

        &::before {
          position: absolute;
          content: '';
          inset: 0;
        }
      }

      &::after {
        content: '';
        left: -2rem;

        top: 0;

        position: absolute;
        height: 100%;
        width: 1ch;
        opacity: 0;
        transition: all 200ms ease-in;
        background-color: var(--color-link);
      }

      &:hover {
        &::after {
          opacity: 1;
          width: 1ch;
        }
      }
    }
  }
}

body.blog-post {
  article {
    margin-block-end: 10em;

    ul {
      padding: 1rem 3ch;

      li {
        margin-block-end: 0.5em;
      }
    }

    p {
      margin-block-end: 1rem;
      max-width: 60ch;
      font-weight: 300;

      code {
        font-family: var(--font-family-code);
        background-color: black;
        color: var(--color-accent);
        padding: 0 .5ch;
      }


      img {
        padding: 1em 0;
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    pre {
      margin-block-end: var(--separation-block);
    }

    :where(p+h1, p+h2, p+h3) {
      padding-block-start: 1rem;
    }
  }

  .blog-post-hero {
    @extend .blog-hero;
  }
}