
body.page {

  .page-hero {
    padding: 5em 1em;
    border: 0px solid var(--color-accent);
    border-width: 0.1em 0;
    margin-block-end: 5em;
  
    h1 {
      --size-base: calc(var(--font-size-title-base) * 2);
      margin-bottom: 0.25em;
    }
  
    .additional-info {
      display: flex;
      gap: 3ch;
  
      .by-line {
        font-style: italic;
      }
    }

  }

  main {
    article {
      font-family: 'Lexend', 'Maven Pro', 'Lato', 'Helvetica Neue', 'Helvetica', sans-serif;
      font-size: 1.125rem;
      line-height: 1.5;
      font-weight: 333;
      
    }
    article#terms{
      counter-reset: h2termscounter;
      padding-block-start: 2em;
      h1 { font-size: 2em; counter-reset: h2termscounter; }
      h1 + p {
        &:before { content: ''; }
      }
      h2 {
        list-style-type: decimal;
        counter-reset: articlestermscounter;
      }
      h2:before {
        content: counter(h2termscounter) ".\0000a0\0000a0";
        counter-increment: h2termscounter;
        
      }
  
      p {
        max-width: 70ch;
        list-style-type: decimal;
        counter-increment: articlestermscounter;
        margin-block-end: 1em;
      }
      p:before {
        font-weight: 400;
        content: counter(h2termscounter) "." counter(articlestermscounter);
        padding-inline-end: 1ch;
      }
      p + h2 {
        margin-block-start: 2rem;
      }
    }

    .team-picture-container {
      // display: grid;
      // grid-template-columns: repeat(3, 1fr);
      // grid-auto-rows: minmax(256px, 1fr);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: var(--separation-block);
      img { 
        position: relative;
        width: 256px;
        box-shadow: inset 0 0 0 128px white, 0 0 0 0 white;
        //box-shadow: 0 0 0 0 var(--color-accent);
        transform: scale(1.0);
        z-index: 1;
        // transition: all 100ms ease-out;
        clip-path: polygon(0 0, 0 -100vmax, 100% -100vmax, 100% 0, 100vmax 0, 100vmax 100%, 100% 100%, 100% 100vmax, 0 100vmax, 0 100%, -100vmax 100%, -100vmax 0);
        //clip-path: inset(0 -100vmax);

        // &:hover {
        //   transform: scale(1.25);
        //   box-shadow: inset 0 0 0 0 var(--color-accent), 0 0 0 1.25ch var(--color-accent);
        // }
        &:hover {
          transition: all 1s ease-out;
          z-index: 2;
          box-shadow: inset 0 0 0 128px white, 0 0 0 100vmax var(--color-accent);
          transform: scale(1.125);
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          height: 256px;
          width: 256px;
          //box-shadow:  0 0 0 100vmax var(--color-background-footer);
          //clip-path: inset(0 -100vmax);
        }
      }
      margin-block-end: var(--separation-block-xl);
    }
  }  

  .two-cards {
    display: grid;
    
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;

    gap: 4em;

    .card {
      min-height: 15rem;
      max-width: 30ch;
      font-size: 1.5rem;

      padding: 3rem 2rem;

      border: 1px solid var(--color-accent);
      
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .strong { font-weight: 700; }
    }
  }
  section.rogue {
    min-height: 40vh;
    padding-block: 2em;
    p { margin-bottom: 2rem; }

    p.premise { font-size: 2em; margin-block-end: 3em; }
  }
  section.projects {
    p {
      max-width: 45ch;
      margin-block-end: var(--separation-block-paragraph);
      font-size: 1.125em;
    }
  }

  &.projects-page {
    &::before {
      height: 100vh;
      display: none;
    }

    @include media-breakpoint-up(lg) {
      &::before {
        height: 100vh;
        width: clamp(20ch, 10vw, 20rem);
        background-image: url(/assets/honeycomb-slim.png);
        background-repeat: repeat-y;
        background-size: contain;
        z-index: 10;
        content: '';
        position: fixed;
        top: 0rem;
        right: 5vw;
        opacity: 0.25;
        display: block;
      }
    }
  }
}
